(function($) {
  /**
   * fixedHeightArticles()
   *
   * Sets fixed heights to articles and promos
   *
   * @returns null
   */
  function fixedHeightArticles() {
    var imageCardHeight = 520,
        textCardHeight = 245,
        ctaCardHeight = 250;

    $('.promo').each(function(i, el) {
      var $promo = $(this);
          promoHeight = $promo.height(),
          $promoContent = $('.promo-content', this),
          promoContentHeight = $promoContent.outerHeight(),
          heightDifference = (ctaCardHeight - promoHeight),
          newPromoContentHeight = (promoContentHeight + heightDifference);

      $promoContent.css('height', newPromoContentHeight);
    });

    $('.article').each(function(i, el) {
      var $article = $(this);
          articleHeight = $article.height(),
          $articleContent = $('.article-content', this),
          articleContentHeight = $articleContent.outerHeight();

      if ($(this).hasClass('article--image')) {
        var heightDifference = (imageCardHeight - articleHeight);
      } else if ($(this).hasClass('article--text')) {
        var heightDifference = (textCardHeight - articleHeight);
      }

      var newArticleContentHeight = (articleContentHeight + heightDifference);

      $articleContent.css('height', newArticleContentHeight);
    });
  }


  /**
   * autoHeightArticles()
   *
   * Sets automatic heights to articles
   *
   * @returns null
   */
  function autoHeightArticles() {
    $('.article').each(function(i, el) {
      var $articleContent = $('.article-content', this);

      $articleContent.css('height', 'auto');
    });
  }


  /**
   * getMobileOperatingSystem()
   *
   * Determine the mobile operating system
   *
   * @returns {string}
   */
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var iOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];

    while (iOS.length) {
      if (navigator.platform === iOS.pop()) {
        return 'iOS';
      }
    }

    if (userAgent.match( /Android/i )) {
      return 'Android';
    } else {
      return 'unknown';
    }
  }


  /**
   * setMobileBodyClasses()
   *
   * Determine the mobile operating system and set `<body>` classes as appropriate
   *
   * @returns null
   */
  function setMobileBodyClasses() {
    var $body = $('body');

    if (getMobileOperatingSystem() == "iOS") {
      $body.addClass('device-ios');
    } else if (getMobileOperatingSystem() == "Android") {
      $body.addClass('device-android');
    }
  }


  /**
   * loadDisqus()
   *
   * Load Disqus comments at our will and track commenting activity if we wish
   *
   * @returns null
   */
  function loadDisqus() {
    var disqusProtocol = "https://";
    var hostName = window.location.hostname;
    var pathName = window.location.pathname;
    var threadUrl = hostName + pathName;

    // Determine proper protocol for Disqus URL and identifier
    // TODO: Maybe abstract this into a function that takes "window.location.hostname" and returns a value
    //       so we can determine which protocol to use
    if (window.location.hostname == "localhost" || window.location.hostname == "asanablog.staging.wpengine.com") {
      disqusProtocol = "http://";
    }

    // Lazy-loading Disqus courtesy of
    // https://github.com/osvaldasvalutis/disqusLoader.js
    var options = {
      scriptUrl: '//asanablog.disqus.com/embed.js',
      laziness: 1,
      throttle: 250,
      disqusConfig: function() {
        this.page.url = String(disqusProtocol + threadUrl);
        this.page.identifier = String(disqusProtocol + threadUrl);
      }
    };

    $.disqusLoader('#disqus_thread', options);
  }


  /**
   * setupMasonry()
   *
   * This sets up our masonry layout for posts
   *
   * @returns null
   */
  function setupMasonry() {
    var masonry,
        masonryContainer = document.querySelector('.articles-row--infinite');

    imagesLoaded(masonryContainer, function() {
      masonry = new Masonry(masonryContainer, {
        columnWidth: '.article-column',
        gutter: 0,
        itemSelector: '.article-column',
        percentPosition: true,
        transitionDuration: '0.15s'
      });
    });

    // NOTE: this is a callback for updating layout with infinite scroll
    // setInterval( function() {
    //   $('.articles-row--infinite').masonry().masonry('reloadItems');
    // }, 0);
  }


  /**
   * stackoverflow.com/questions/46155/validate-email-address-in-javascript
   * @param email {string} a test string
   * @returns {boolean} whether or not string is a valid email address
   */
  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /**
   * On Window Load
   */
  $(document).ready(function() {

    


    // Variables
    var $body = $('body'),
        $window = $(window),
        windowWidth = $window.width(),
        $result = $("#result"),
        $submit = $("#validate"),
        $emailInput = $("#email");

    function validate() {
      $email = $("#email").val();
    
      if (validateEmail($email)) {
  
        $("#validate").prop( "disabled", false );
        $result.hide();
        
      } else {
        $submit.prop( "disabled", true );
        $result.text($email + " is not a valid email");
        $result.css("color", "red");
      }
      return false;
    }
        
    $emailInput.on("blur", validate);



    // Run functions
    if (! $body.hasClass("single") && ! $body.hasClass('page')) {
      setupMasonry();
    }

    setMobileBodyClasses();

    if (windowWidth > 768) {
      fixedHeightArticles();
    }

    $('.dialog--flow form').submit(function() {
      AsanaHelpers.showModal("loading");
    });

    $('.disqus-comment-count, .meta-comments').on('click', function() {
      window.location.hash = '#disqus_thread';
    });

    // Hover replace "xx Shares" with share icons on cards
    // TODO: Add this to the callback for the infinite scroll (replace `$` with `jQuery`)
    $(".article-footer-comments").on("mouseenter", ".article-footer-shares", function() {
      var $this = $(this);

      setTimeout(function() {
        $this.hide(0);
        $this.parent().find('.article-footer-sharenow').fadeIn('slow').css("display", "inline-block");
      }, 100);
    }).on("mouseleave", ".article-footer-sharenow", function() {
      var $this = $(this);

      setTimeout(function() {
        $this.stop().hide(0);
        $this.parent().find('.article-footer-shares').fadeIn('slow');
      }, 750);
    });

    // Post Recommendations
    var $vote = $('.vote');

    $vote.find('a').on('click', function(e) {
      $vote.text('Thanks for your feedback!');
    });

    // Confirmation CTA
    $('.why-dialog').on('click', function() {
      $('.extra-info').fadeIn();
    });

    // Add to Asana button
    $('.title .essb_links ul.essb_links_list').append(
      '<li class="essb_item essb_link_addasana nolightbox"><a class="addAsana social" href="#addAsana" rel="nofollow" title="Add this article to Asana"></a></li>'
    );

    // Mobile bottom bar
    $('#subscription button').on('click', function() {
      var $mobilebar = $('.mobilebar');

      setTimeout(function() {
        window.location.hash = "close";
      }, 1500);

      if (! $body.hasClass('single')) {
        $mobilebar.hide();
      } else if ($body.hasClass('single')) {
        $mobilebar.find('.float--right').hide();
      }
    });

    // Update class names for social icons
    $('.essb_item').each(function() {
      var $essbicon = $('.essb_icon', this);

      if ($(this).hasClass('essb_link_twitter')) {
        $essbicon.addClass('social social--twitter').removeClass('essb_icon');
      } else if ($(this).hasClass('essb_link_facebook')) {
        $essbicon.addClass('social social--facebook').removeClass('essb_icon');
      } else if ($(this).hasClass('essb_link_linkedin')) {
        $essbicon.addClass('social social--linkedin').removeClass('essb_icon');
      } else if ($(this).hasClass('essb_link_mail')) {
        $essbicon.addClass('social social--mail').removeClass('essb_icon');
      }
    });
  });


  /**
   * After Window Loads
   */
  $(window).bind("load", function() {
    var $body = $('body');

    // Count social shares for template
    setTimeout(function() {
      if ($body.hasClass('page')) {
        var shareCount = 0,
            commentCount = 0,
            totalPosts = ($('.js-shareCount').length - 1);

        $('.js-shareCount').each(function(i) {
          count = $('.essb-total-value', this).text();

          shareCount += parseInt(count);

          if (i == totalPosts) {
            $('.js-totalShares').text(shareCount);
          }
        });

        $('.js-commentCount').each(function(i) {
          count = $(this).text();

          commentCount += parseInt(count);

          if (i == totalPosts) {
            $('.js-totalComments').text(commentCount);
          }
        });
      }
    }, 3000);
  });


  /**
   * On Window Scroll
   */
  $(window).scroll(function() {
    if ($('body').hasClass("single")) {
      var $slidein = $('.slidein');
      var targetHeight = $(window).height();
      var scrollPos = $(document).scrollTop();

      // Slide in CTA
      if (AsanaHelpers.getCookie('subscribed_email_list_4792736') != 1) {
        if (scrollPos >= targetHeight) {
          $slidein.addClass('slidein--isShown');
        } else {
          $slidein.removeClass('slidein--isShown');
        }
      }

      // Slide-in post title w/social shares
      var $title = $('.title');

      if (scrollPos > 200) {
        $title.addClass('is-active');
      } else {
        $title.removeClass('is-active');
      }
    }
  });


  /**
   * On Mobile Touch/Move
   */
  $(window).bind('touchmove', function(e) {
    // Mobile bottom bar
    var $mobilebar = $('.mobilebar'),
        lastScrollTop = 0,
        scrollPos = $(this).scrollTop();

    if (scrollPos > lastScrollTop) {
      setTimeout(function() {
        $mobilebar.addClass('mobilebar-isHidden');
      }, 200);
    } else {
      setTimeout(function() {
        $mobilebar.removeClass('mobilebar-isHidden');
      }, 200);
    }

    lastScrollTop = scrollPos;
  });


  /**
  * On Window Resize
  */
  $(window).resize(function() {
    // Determine whether to have fixed or auto height articles
    var $window = $(window),
        windowWidth = $window.width();

    if (windowWidth <= 768) {
      autoHeightArticles();
    } else if (windowWidth > 768) {
      fixedHeightArticles();
    }
  });


  /**
   * CrazyEgg
   */
  try {
    setTimeout(function(){var a=document.createElement("script");
      var b=document.getElementsByTagName("script")[0];
      a.src=document.location.protocol+"//script.crazyegg.com/pages/scripts/0031/2742.js?"+Math.floor(new Date().getTime()/3600000);
      a.async=true;a.type="text/javascript";b.parentNode.insertBefore(a,b)}, 10);
  } catch(e) {
    console.log(e);
  }
})(jQuery);
