(function($) {
  /**
   * gaDataLayerPush()
   *
   * Set up GA data layer pushes for clicking certain elements
   *
   * @param event {string} 'UA-Login', 'UA-Signup', 'UA-BadAuth', 'signup', 'ExistingUserSignUp'
   * @param eventCategory {string} 'Login', 'Signup',  'Video', 'Blog', 'Contact', 'Social'
   * @param eventAction {string} 'Click', 'Play', 'Subscribe', 'Mail', 'GoogleAuth', 'Submit'
   * @param eventLabel {string} 'Header', 'Modal', 'Homepage', 'Jobs Video', 'Support'
   *
   * @returns null
   */
  function gaDataLayerPush(element, event, eventCategory, eventAction, eventLocation) {
    var eventCategory = eventCategory || null,
        eventAction   = eventAction   || null,
        eventLocation = eventLocation || null;

    $(element).on('click', function() {
      dataLayer.push({
        'event': event,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLocation': eventLocation
      });
    });
  }


  /**
   * On Window Load
   */
  $(document).ready(function() {
    gaDataLayerPush(
      '.siteHeader-buttons a:first-of-type',
      'SignUp',
      'SignUp',
      null,
      'Blog Header'
    );

    gaDataLayerPush(
      '.siteHeader-buttons a:last-of-type',
      'Login',
      'Login',
      null,
      'Blog Header'
    );

    gaDataLayerPush(
      'body.home .subscription form button',
      'Subscribe',
      'Blog',
      'Subscribe',
      'Homepage'
    );

    gaDataLayerPush(
      '.post p a',
      'Outbound Click'
    );

    gaDataLayerPush(
      '.title .essb_link_facebook a',
      'Share',
      'Blog',
      'Facebook',
      'Sidebar'
    );

    gaDataLayerPush(
      '.title .essb_link_twitter a',
      'Share',
      'Blog',
      'Twitter',
      'Sidebar'
    );

    gaDataLayerPush(
      '.title .essb_link_linkedin a',
      'Share',
      'Blog',
      'LinkedIn',
      'Sidebar'
    );

    gaDataLayerPush(
      '.title .essb_link_mail a',
      'Share',
      'Blog',
      'Email',
      'Sidebar'
    );

    gaDataLayerPush(
      '.relatedPosts a',
      'Related Article'
    );

    gaDataLayerPush(
      'body.single .subscription form button',
      'Subscribe',
      'Blog',
      'Subscribe',
      'Post'
    );

    $('a.vote-up').on('click', function(e) {
      dataLayer.push({
        'event': 'Blog Post Voting',
        'eventCategory': 'Blog',
        'eventAction': 'Upvote',
        'eventLocation': 'Blog'
      });
    });

    $('a.vote-down').on('click', function(e) {
      dataLayer.push({
        'event': 'Blog Post Voting',
        'eventCategory': 'Blog',
        'eventAction': 'Downvote',
        'eventLocation': 'Blog'
      });
    });
  });
})(jQuery);
